<template>
  <div class="animated fadeIn">
     <CRow>
      <el-form ref="queryParams" :model="queryParams" label-width="100px" :inline="true">
        <el-form-item label="關鍵字">
          <el-input v-model="queryParams.qstr" placeholder="日期、主題、備註"></el-input>
        </el-form-item>
        <el-form-item label="分類">
          <el-cascader :props="props" v-model="queryParams.categories" :options="categories" clearable></el-cascader>
        </el-form-item>
        <el-form-item label="標籤">
            <el-select v-model="queryParams.tags" clearable multiple filterable placeholder="請選擇">
              <el-option
                v-for="tag in tags"
                :key="tag.id"
                :label="tag.name"
                :value="tag.id">
              </el-option>
            </el-select>
        </el-form-item>
         <el-form-item>
           <el-button type="primary" @click="currentPage=1;query(pageSize,0,sort,order, queryParams)">查詢</el-button>
           <el-button type="" @click="batchApprovae">批次核准</el-button>
           <el-button type="danger" @click="batchReject">批次取消核准</el-button>
        </el-form-item>
      </el-form>

    </CRow>
    <CRow>
    <el-table
            :data="audios"
            style="width:100%"
            border
            
            empty-text="暫無數據"
            :row-class-name="tableRowClassName"
            :default-sort = "{prop: 'dateCreated', order: 'descending'}"
            @sort-change = "sortChange"
            @selection-change="handleSelectionChange"
          >
           <el-table-column
              type="index"
              :index="indexFormatter"
              fixed
              >
            </el-table-column>
          <el-table-column
          fixed
            type="selection"
            width="45">
          </el-table-column>
            <el-table-column
              fixed="left"
              label="操作"
              width="170">
              <template slot-scope="scope">
                <el-button
                  @click.native.prevent="approvae(scope.row.id)"
                  size="mini">
                  核准
                </el-button>
                <el-button
                  @click.native.prevent="reject(scope.row.id)"
                  type="danger"
                  size="mini">
                  取消核准
                </el-button>
              </template>
          </el-table-column>
            <el-table-column
            prop="is_approved"
            :formatter="isApprovedFormat"
            label="審核"
            width="80">
          </el-table-column>
            <el-table-column
                label="開示日期"
                prop="audio.audio_no"
                width="120"
                sortable="custom"
            >
            </el-table-column>
            <el-table-column
                label="開示主題"
                prop="audio.title"
                sortable="custom"
            >
         </el-table-column>
                <el-table-column
                label="群組"
                sortable = "custom"
                prop="name"
            >
             <template slot-scope="scope">
                     <el-tag effect="dark" :type="scope.row.group.type">{{scope.row.group.name}}</el-tag>
             </template>
            </el-table-column>
                  <el-table-column
                label="標籤"
            >
             <template slot-scope="scope">
                 <span class="tag_group">
                     <el-tag v-for="audioToAudioTag in scope.row.audio.audioToAudioTags" :key="audioToAudioTag.id" :type="audioToAudioTag.tag.type">{{audioToAudioTag.tag.name}}</el-tag>
                 </span>
                 
             </template>
            </el-table-column>
            <el-table-column
                label="分類"
                 prop="audio.category"
                 :formatter="categoryFormatter"
            >
            <!--
            <template slot-scope="scope">
              <keep-alive>
                {{ categoryFormatter(scope.row.category.id)}}
              </keep-alive>
            </template>
            -->
            </el-table-column>
                <el-table-column
                label="備註"
                prop="audio.description"
                sortable="custom"
            >
            </el-table-column>

          </el-table>

    </CRow>
    <CRow>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-sizes="[10, 25, 50]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
    </CRow>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Vue from 'vue'
import API from '@/api/'
import moment from "moment-timezone";
Vue.filter('converDate',function(value){
  if(value){
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return moment(value).tz(tz).format('YYYY-MM-DD');
  }else{
    return value;
  }

})
export default {
  name: 'AudioList',
   props: ['id'],
  created () {
      this.$store.dispatch('groupAudioApproval/list', {id: this.$route.params.id, max: this.pageSize, offset: 0, sort: this.sort, order: this.order}, {
          root: true
      });
      this.$store.dispatch('tag/list', {max: 1000, offset: 0}, {
          root: true
      });
      this.$store.dispatch('category/list', {max: 1000, offset: 0}, {
          root: true
      });
  },
  data() {
        return {
          pageSize:10,
          currentPage:0,
          loading: false,
          multipleSelection: [],
          batchForm: {
            tagAction: '',
            tags: [],
            category: [],
            start_date: null,
            end_date: null
          },
          queryParams: {
            tags:[],
            categories:'',
            queryParams:''
          },
          props: {
            value: 'id',
            label: 'name',
            checkStrictly: true
          },
          dialogBatchEditFormVisible: false,
          dialogFormVisible: false,
          formLabelWidth: '120px',
          rules: {
            audio_no: [
              { required: true, message: '請輸開示日期', trigger: 'blur' },
            ],
            title: [
              { required: true, message: '請輸開示主題', trigger: 'blur' },
            ],
            start_date: [
              { required: true, message: '請輸生效日期', trigger: 'blur' },
            ],
            category: [
              { required: true, message: '請選擇分類', trigger: 'blur' },
            ]
          },
          tagExps: [],
        }
  },
  methods:{
        handleSelectionChange(val) {
        this.multipleSelection = val;
    },
    batchApprovae() {
      for(const data of this.multipleSelection){
        this.approvae(data.id)
      }
    },
        batchReject() {
      for(const data of this.multipleSelection){
        this.reject(data.id)
      }
    },
      approvae(value){
      this.$store.dispatch('groupAudioApproval/update', {id: value, is_approved: true}, {
          root: true
      });
       // this.$store.commit('groupAudioApproval/SET_IS_APPROVED',{id: value, is_approved: true},{root: true})
      },
      reject(value){
        this.$store.dispatch('groupAudioApproval/update', {id: value, is_approved: false}, {
            root: true
        });
        //this.$store.commit('groupAudioApproval/SET_IS_APPROVED',{id: value, is_approved: false},{root: true})
      },
    handleSizeChange(val) {
      this.pageSize = val;
      this.query(val,this.offset, this.sort, this.order,this.queryParams);
      },
      handleCurrentChange(val) {
        this.query(this.pageSize,this.pageSize*(val-1), this.sort, this.order,this.queryParams);

      },
    query(max,offset,sort ,order, params = {}) {
      this.$store.dispatch('groupAudioApproval/list', {id: this.$route.params.id, max: max, offset: offset, sort, order, ...params}, {
          root: true
      });
    },
    indexFormatter(index){
     return `#${parseInt(this.offset)+ index + 1}`;

    },
    categoryFormatter(row, column, cellValue) {
      if(row.ancestors){
        return row.ancestors.map(a=>a.name).join(' / ');
      } else {
        return row.category.name;
      }
    },
    tableRowClassName({row, rowIndex}) {
        if (row.is_approved === null) {
          return '';
        } else if (row.is_approved) {
          return 'success-row';
        } else {
            return 'warning-row';
        }
    },
    isApprovedFormat(row, column, cellValue){
        if (cellValue === null) {
            return '待審核';
        } else {
            return cellValue ? '核准': '拒絕';
        }
    },
    dateFormatter(row, column, cellValue){
      if(cellValue){
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return moment(parseInt(cellValue)).tz(tz).format('YYYY-MM-DD');
        //return moment(cellValue).format('YYYY-MM-DD');
      }else{
        return cellValue;
      }

    },
    sortChange(args) {
      if (args.order === null) {
        this.$store.commit('groupAudioApproval/SET_SORT','id',{root: true})
        this.$store.commit('groupAudioApproval/SET_ORDER','ascending',{root: true})
      } else {
        this.$store.commit('groupAudioApproval/SET_SORT',args.prop,{root: true})
        this.$store.commit('groupAudioApproval/SET_ORDER',args.order,{root: true})
      }
      
      this.query(this.pageSize,this.offset,this.sort,this.order,this.queryParams)
    },

  },
  computed: {
      ...mapGetters({
          // 取得資料夾列表
          audios: 'groupAudioApproval/audios',
          total:'groupAudioApproval/total',
          max:'groupAudioApproval/max',
          offset:'groupAudioApproval/offset',
          categories: 'category/categories',
          subCategories: 'subCategory/subCategories',
          tags: 'tag/tags',
          getAudioById: 'groupAudioApproval/getAudioById',
          sort: 'groupAudioApproval/sort',
          order: 'groupAudioApproval/order',
      }),
  },
}
</script>

<style scoped>
  .tag_group .el-tag+.el-tag {
    margin-left: 10px;
  }
  .el-transfer >>> .el-transfer-panel, .el-cascader {
    width: 350px;
  }
  .el-date-editor.el-input{
    width: 150px;
  }
  .el-transfer >>> .el-transfer__buttons {
    width: 100px;
  }
  .el-transfer >>> .el-transfer__buttons .el-button+.el-button {
    margin-left: 0px;
  }
  .el-table >>>  .warning-row {
    background: oldlace;
  }

  .el-table >>> .success-row {
    background: #f0f9eb;
  }
</style>
